.gallery {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  align-items: center;
  padding: 20px;
  width: 100%;
  overflow-x: auto;
  .grid{
    align-items: center;
  }
  .image-container{
    >div{
      @media screen and (max-width: 570px){
        padding:15px 0;
      }
    }
  }
}

.image-container img {
  width: 100%;
  max-width:100px;
  height: auto;
  transition: filter 0.3s ease;
  filter: grayscale(100%);
}

.image-container img:hover {
  filter: grayscale(0%);
}
